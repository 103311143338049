@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root{
  --green100: #edf5f3;
  --green200: #b9d6ce;
  --green300: #81ada2;
  --green400: #5b8f81;
  --green500: #2A5B4F;
  --green600: #19473c;
  --green700: #113b32;
  --green800: #0C231E;
  --green900: #03221b;
  --red100: #dfb2bf;
  --red200: #c5768e;
  --red300: #b44c6b;
  --red400: #a33556;
  --red500: #9D2449;
  --red600: #851b3b;
  --red700: #68142d;
  --red800: #470b1d;
  --red900: #29040f;
  --gold: #D4C19C;
  --gray100: #D6EEEE;
  --gray200: #DDDDDD;
  --gray300: #A9A8A9;
  --gray400: #96A5A5;
  --gray500: #758484;
  --gray600: #5B6C6C;
  --gray700: #6f7271;
  --gray800: #404041;
  --gray900: #141F1F;
  --white: #f2f3f9;
  --black: #00030a;
  --black50: #0007057a;
  --text-color: bisque;
  --bg-color: rgba(34, 7, 82, 0.61);
  --bg-card-color: rgb(97, 23, 234);
  --border-radius: 0.6rem;
  --transition: 0.6s ease-in-out;
  --colorTexts: whitesmoke;
  

}

*,
::before,
::after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'GMX-regular';
  src: url('../assets/fonts/GMX-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

a.link{
  color: var(--gold);
  text-decoration:none;
}

a.link:hover{
  color: var(--red500);
  text-decoration:none;
}
.link_medium{
  color: var(--white);
  text-decoration:none;
  font-family: 'Montserrat';
  font-size: 14px;
}

.link_medium:hover {
  color: var(--red500);
  text-decoration:none;
  font-family: 'Montserrat';
  font-size: 14px;
}
p {
  font-family: 'Montserrat';
  font-size: 18px;
}

p.small {
  font-family: 'Montserrat';
  font-size: 16px;
}

p.card {
  font-family: 'Montserrat';
  font-size: 14px;
  color: var(--white);
  text-shadow: 1px 1px 8px rgba(0,0,0,0.6);
  min-width: 400px;
}



.contenedor{
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 1.2rem 0 0 0;
}

.text-wrapper{
  position: absolute;
  top: 10%;
  left: 10%;
}
.centrado{
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centrado_top{
  position: absolute;
  top: 700px;
  left: 50%;
  transform: translate(-50%, -50%);
}

body{
  background-color: var(--green100);
  color: var(--gray900);
  transition: var(--transition);
  font-family: 'Montserrat';
  margin-top: 200px;
}

#body{
  background-color: var(--green100);
  color: var(--gray900);
  transition: var(--transition);
  font-family: 'Montserrat';
  font-size: 18px;
}

#spam{
  display: none;
}

.header {
  display: flex;
  align-items: left;
  justify-content: space-evenly;
  width: 100%;
  padding: 0.5em;
  background-color: var(--black);
  background: radial-gradient(
      circle,
      var(--green700) 10%,
      var(--green900) 90%
  );
  transition: var(--transition);
  border-bottom: 4px solid var(--red800);
  color: var(--white);
}

.header1{
  position: relative;
  color: var(--white);
  text-shadow: 1px 1px 8px rgba(0,0,0,0.6);
}

.small{
  font-size: 0.7em;
}

h1 {
  font-size: 40px;
  margin-bottom: 1.2em;
  text-align: center;
  margin-top: 2.6em;
  color: var(--red500);
  font-family: 'GMX-regular', Helvetica, sans-serif;
  text-shadow: 1px 1px 2px var(--black), 0 0 25px var(--green900), 0 0 5px var(--gray900);
  
}

h2 {
  font-size: 40px;
  margin-bottom: 1.2em;
  text-align: center;
  margin-top: 1em;
  color: var(--red500);
  font-family: 'GMX-regular', Helvetica, sans-serif;
  text-shadow: 1px 1px 2px var(--black), 0 0 25px var(--green900), 0 0 5px var(--gray900);
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 1.2em;
  text-align: center;
  margin-top: 1.2em;
  color: var(--gray900);
  font-family: 'GMX-regular', Helvetica, sans-serif;
  background-color: var(--white);
}
/*BUTTONS */

.button-primary {
  background-color: var(--green500); 
  border: none;
  color: var(--white);
  padding: 20px;
  border-radius: var(--border-radius);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-primary:hover {
  border: 3px var(--green500);
  color: var(--black);
  padding: 20px;
  border-radius: var(--border-radius);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-ghost {
  border: solid 3px;
  border-color: var(--gray500);
  color: var(--gray500);
  padding: 20px;
  border-radius: var(--border-radius);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-ghost:hover {
  border-top: solid 3px;
  border-color: var(--gold);
  color: var(--gold);
  padding: 20px;
  border-radius: var(--border-radius);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-ghost2 {
  color: var(--gray100);
  height: 100px;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-ghost2:hover {
  border-top: solid 3px;
  height: 100px;
  border-bottom: solid 3px;
  border-color: var(--gold);
  color: var(--gold);
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-primary-small {
  border-radius: var(--border-radius);
  border: solid 3px;
  border-color: var(--red500);
  margin-right: 10px;
  color: var(--gray100);
  background-color: var(--red500);
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  
}

.button-primary-small:hover {
  color: var(--gray800);
  background-color: var(--gold);
  border-radius: var(--border-radius);
  border: solid 3px;
  margin-right: 10px;
  border-color: var(--gray800);
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-secondary-small {
  color: var(--gray800);
  border-radius: var(--border-radius);
  border: solid 3px;
  border-color: var(--gray800);
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-secondary-small:hover {
  border-radius: var(--border-radius);
  border: solid 3px;
  border-color: var(--gray800);
  color: var(--gray100);
  background-color: var(--gray800);
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.error {
  color: var(--red500);
  font-size: 0.8rem;
  margin-top: 0.2rem;
}



/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: var(--bg-color);
  color: var(--colorTexts);
  cursor: pointer;
  padding: 18px;
  width: fit-content;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .collapsible:hover {
  background-color: var(--bg-card-color);
  color: var(--red400);
  
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  
  font-size: 0.9rem;
  line-height: 1.5;
  text-align: justify;
  align-items: center;
  margin-bottom: 1em;
  max-width: 600px;
}

/* CARDS */
.card{
  padding: 1em;
  background-color: var(--black);
  border-radius: var(--border-radius);
  border: 1px solid;
  color: whitesmoke;
  background: -webkit-linear-gradient(to right, var(--green900), var(--black));
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--green900), var(--black));
  opacity: 0.5;
}

.card:hover{
  padding: 1em;
  background-color: var(--black);
  border-radius: var(--border-radius);
  border: 1px solid;
  color: whitesmoke;
  background: -webkit-linear-gradient(to right, var(--green900), var(--black));
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--green900), var(--black));
  opacity: 0.95;
}

.card__infography{
  align-items: center;
  color: var(--gold);
  font-size: 26px;
  width: 100%;
}

.card__title{
  margin-top: 2em;
  padding: auto;
  text-align: center;
  font-family: 'Labrada';
  margin-bottom: 1em;
  font-size: 2.6rem;
  color: var(--green300);
}

.card__subtitle{
  font-size: 1.5rem;
  font-family: 'Labrada';
  opacity: 0.8rem;
  margin-bottom: 0.8rem;
  margin-top: 0.5em;
  color: var(--red500);
}

.card__link{
  margin-bottom: 0.5em;
}

.card__text{
  font-size: 0.9rem;
  line-height: 1.5;
  text-align: justify;
  -webkit-text-stroke-color: var(--green100);
  color: var(--gray200);
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  height: fit-content;
  background: radial-gradient(
      circle,
      var(--green800) 10%,
      var(--green700) 90%
  );
  padding: 4em;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.4em;
}


.card__image-container{
  align-items: center;
  align-self: center;
  align-content: center;
  display: flex;
  min-width: 320px;
  border-radius: var(--border-radius);
  overflow: hidden;    
}

.infographic{
  max-width: 800px;
  max-height: 2000px;
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: var(--green300);
  margin-bottom: 1em;
  overflow: hidden;
}

.item__infography{
  padding: 0.8rem;
  border: 1px solid;
  border-color: var(--green300);
  background-color: var(--green700); opacity: 0.7;
  overflow: hidden;
}

.item__infography:hover{
  padding: 0.8rem;
  border: 3px solid;
  border-color: var(--red500);
  background-color: var(--green700); opacity: 0.95;
  overflow: hidden;
  color: var(--white);
}

/* MENU */
.wrapper{ 
  height:2000px; 
  width:100%; 
  padding-top:20px;
}

.fixed{
  position:fixed; 
  top:0;
}

label{
  color: var(--colorTexts);
  font-size: 16px;
  position: absolute;
  left: 5px;
  top:10px;
  transition: 0.5s ease all;
  pointer-events: none;
}

.vertical-menu {
  width: fit-content; /* Set a width if you like */
  align-self: center;
  flex-direction: row;
}

.vertical-menu a {
  background-color: var(--bg-color); /* Grey background color */
  color: whitesmoke; /* White text color */
  display: flex; /* Make the links appear below each other */
  padding: 10px; /* Add some padding */
  text-decoration: none; /* Remove underline from links */
  border-style: solid;
  align-self: center;
}

.vertical-menu a:hover {
  background-color: #04AA6D; /* Dark grey background on mouse-over */
  filter: drop-shadow(8px 8px 10px gray);
}

.vertical-menu a.active {
  background-color: #04AA6D; /* Add a green color to the "active/current" link */
  color: yellow;
}
/* GLOWING TEXT */
.glow {
  color: var(--white);
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px var(--white), 0 0 20px var(--white), 0 0 30px var(--green500), 0 0 40px var(--green500), 0 0 50px var(--green500), 0 0 60px var(--green500), 0 0 70px var(--green500);
  }
  to {
    text-shadow: 0 0 20px var(--white), 0 0 30px var(--green200), 0 0 40px var(--green200), 0 0 50px var(--green200), 0 0 60px var(--green200), 0 0 70px var(--green200), 0 0 80px var(--green200);
  }
}

p .onImage{
  font-size: medium;
  font-family: 'Manrope', sans-serif;
  color: var(--green100);
  padding: 1rem;
  text-align: justify;
  width: 600px;
  height: auto;
  background-color: #0007057a;
}


/*QUOTES APP*/

p .quotes{
  font-size: medium;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--gray200);
  
}

p .authors{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--red300);
  
}

input:focus~.barra::before, textarea:focus~.barra::before{
  width: 100%;
}

p .description{
  font-size: medium;
  font-family: inherit;
  color: var(--red300);
}

/*CARRUSEL */
#carrusel {
  float:left;
  width: 500px;
  overflow:hidden;
  height:203px;
  position:relative;
  margin-top:20px;
  margin-bottom:20px;
}

#carrusel .left-arrow {
  position:absolute;
  left:10px;
  z-index:1;
  top:50%;
  margin-top:-9px;
}

#carrusel .right-arrow {
  position:absolute;
  right:10px;
  z-index:1;
  top:50%;
  margin-top:-9px;
}

.carrusel {
  width: 4000px;
  left:0px;
  position:absolute;
  z-index:0;
}

.carrusel>div {
  float: left;
  height: 203px;
  margin-right: 5px;
  width: 195px;
  text-align:center;
}

.carrusel img {
  cursor:pointer;
}

.product {
  border:#CCCCCC 1px solid;
  width: fit-content;
}


.flex-container{
  display: flex;
  border: white;
  padding: 20px;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  flex-direction: column;
  align-items: center;
}

.horizontal-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 2rem;
}

.horizontal-container-icons{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

.mySlides {display:none;}

/*BOXES */

.box {
  border-radius: var(--border-radius);
  border: 1px solid var(--gray900);
  color: var(--black);
  text-align: justify;
  align-content: center;
  margin-top: 1em;
  margin-left: 10px;
  margin-right: 10px;
  width: 350px;
  padding: 10px;
  align-items: center;
  box-shadow: 10px 10px 8px var(--gray500);
}

.box-banner {
  color: var(--gray300);
  text-align: justify;
  align-content: center;
  margin-top: 1rem;
  width: 100%;
  height: fit-content;
  align-items: center;
}

.box-borderless {
  text-align: justify;
  align-content: center;
  margin-top: 1em;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  padding: 10px;
  align-items: center;
}

.box-image-container {
  border-radius: var(--border-radius);
  align-content: center;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  max-width: 700px;
  height: fit-content;
  align-items: center;  
  overflow: hidden;
  transition: transform .2s; 
}

.box-image-profile {
  display: flex;
  border-radius: var(--border-radius);
  align-content: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2em;
  max-width: 700px;
  height: fit-content;
  align-items: center;  
  overflow: hidden;
  transition: transform .2s; 
}

.box-courses {
  background-color: var(--bg-card-color);
  background: -webkit-linear-gradient(to right, whitesmoke, rgb(182, 197, 187));
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, whitesmoke, rgb(182, 197, 187));
  display: block;
  border-radius: var(--border-radius);
  color: var(--bg-color);
  text-align: justify;
  width: 600px;
  height: auto;
  margin: 10px;
  padding: 1em;

  align-items: center;
  text-decoration: none;
  
}

.box-text {
  background-color: var(--black50); 
  width: fit-content; 
  height: fit-content; 
  border-radius: var(--border-radius);
  text-align: justify;
}

.box-title {
  background-color: var(--black);
  background: -webkit-linear-gradient(to right, var(--black), var(--green900));
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--black), var(--green900));
  border-radius: var(--border-radius);
  border: 1px solid whitesmoke;
  color: white;
  text-align: center;
  align-items: center;
  
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  height: auto;
  
  
  
  padding: 1em;
  box-shadow: 20px 10px 8px var(--gray600);
}

.box-carrusel {
  background-color: var(--bg-card-color);
  background: -webkit-linear-gradient(to right, var(--bg-card-color), var(--bg-color));
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--bg-card-color), var(--bg-color));
  border-radius: var(--border-radius);
  border: 1px solid whitesmoke;
  color: white;
  text-align: center;
  margin: 5px;
  height: auto;
  width: 600px;
  padding: 1em;
  align-self: center;
  box-shadow: 10px 10px 8px #426d1a80;
}

.box-footer {
  background-color: var(--black);
  border-radius: var(--border-radius);
  border: 1px solid whitesmoke;
  color: rgb(241, 243, 202);
  text-align: left;
  width: 60%;
  height: 300px;
  padding: 20px;
  align-items: center;
  box-shadow: 10px 10px 8px var(--gray500);
}

.principal {
  background-size: cover;
  background-position: right;
  color: white;
  text-align: center;
  align-items: center; 
  width: 100%;
  margin-bottom: 1em;
  height: fit-content;
  padding: 1em;
  padding-top: 3em;
}



video {
  width: 100%;
  height: auto;
  display: block;
  max-width: 1230px; 
  margin-top: 100px;
  margin-left: 20%;
  
  top:0; left:0; z-index:-1;
}

@media only screen and (max-width: 768px) {
  
}
/*RESPONSIVE MEDIA FOR NOTEBOOK */

@media only screen and (max-width: 1300px) {
  video {
      margin-top: 100px;
      width: 60%;
      height: auto;
      display: block;
      margin-left: 20%;
  }
  h1 {
      font-size: 40px;
      margin-bottom: 1.2em;
      text-align: center;
      margin-top: 2.6em;
      margin-left: 10%;
      color: var(--red500);
      font-family: 'GMX-regular', Helvetica, sans-serif;
      text-shadow: 1px 1px 2px var(--black), 0 0 25px var(--green900), 0 0 5px var(--gray900);
      
  }
  body{
      background-color: var(--green100);
      color: var(--gray900);
      transition: var(--transition);
      font-family: 'Montserrat';
      margin-top: 200px;
  }
  
  #body{
      background-color: var(--green100);
      color: var(--gray900);
      transition: var(--transition);
      font-family: 'Montserrat';
      font-size: 18px;
  }
  .button-ghost2 {
      color: var(--gray100);
      height: 70px;
      
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      
      cursor: pointer;
  }
  
  .button-ghost2:hover {
      border-top: solid 3px;
      height: 70px;
      border-bottom: solid 3px;
      border-color: var(--gold);
      color: var(--gold);
      
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      
      cursor: pointer;
  }
  .infographic{
      max-width: 800px;
      max-height: 2000px;
      border-radius: var(--border-radius);
      border: 1px solid;
      border-color: var(--green300);
      margin-bottom: 1em;
      overflow: hidden;
  }
  
  .item__infography{
      padding: 0.8rem;
      border: 1px solid;
      border-color: var(--green300);
      background-color: var(--green700); opacity: 0.7;
      overflow: hidden;
  }
  
  .item__infography:hover{
      padding: 0.8rem;
      border: 3px solid;
      border-color: var(--red500);
      background-color: var(--green700); opacity: 0.95;
      overflow: hidden;
      color: var(--white);
  }
  .card__infography{
      align-items: center;
      color: var(--gold);
      font-size: 16px;
      width: 80%;
  }
}

/*////////////////////////////////////////////////////////////// */

.zoom {
  padding: 50px;
  background-color: green;
  transition: transform .2s;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}

figure {
  display: inline-block;
  height: 600px;
  
}
figcaption {
  font-style: oblique;
  text-align: center;
  margin: 10px 0px;
  color: var(--gray700);
}
.imagen img {
  max-width: 100%;
  cursor: pointer;
}
.modalImg {
  cursor: pointer;
  border-radius: 0.4rem;
  max-height: 80%;
}
.imodalImg:hover {
  opacity: 0.8;
}
.modal {
  display: none;
  /* ocultar por defecto */
  position: fixed;
  width: 80%;
  height: 80%;
  position: fixed;
  top: 0;
  left: 0;
  border: solid 1px;
  background: rgba(0, 0, 0, 0.9);
  padding-top: 5%;
  overflow: hidden;
}
#modalImg {
  display: block;
  position: fixed;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);   
}
#modalClose {
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 50px;
  color: var(--green100);
  transition: all;
  font-weight: bold;
  font-size: 5rem;
}
#modalText {
  margin: auto;
  display: block;
  text-align: center;
  color: var(--green100);
}
/* animaciones */
#modalText {
  animation-name: zoom;
  animation-duration: 0.5s;
}

@keyframes zoom {
  from {
      transform: scale(0)
  }
  to {
      transform: scale(1)
  }
}

/*IMAGES BY MODAL */
#myImg {
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: 0.4s;
  margin-top: 3rem;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}


/*SLIDES SET BY OPACITY*/

#slideset {height: 10em; position: relative}
#slideset > * {
  animation: 60s autoplay infinite linear;
  position: absolute; top: 0; left: 0; opacity: 0.0}
@keyframes autoplay {
  0% {opacity: 0.0}
  4% {opacity: 1.0}
  33.33% {opacity: 1.0}
  37.33% {opacity: 0.0}
  100% {opacity: 0.0}
}
#slideset > *:nth-child(1) {animation-delay: 0s}
#slideset > *:nth-child(2) {animation-delay: 20s}
#slideset > *:nth-child(3) {animation-delay: 40s}


/*SMARTPHONE MODE*/
.main{
  display: grid;
  padding: 2em;
  gap: 0.5em;
}

@media screen and (min-width:720px){
  .main{
      grid-template-columns: min-content 1fr; 
  }
}
@media screen and (max-width:720px){
  
  .box-title{
      margin-left: 0;
      margin-right: 0;
      width: 100%;

  }
  .box{
      margin-left: 0;
      margin-right: 0;
      width: 100%; 
  }
  .card__text{
      margin-left: 0;
      margin-right: 0;
      width: 100%; 
  }
  .horizontal-container{
      display: flex;
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
  }
  .box-footer {
      display: flex;
      flex-direction: column;
      background-color: var(--black);
      border-radius: var(--border-radius);
      border: 1px solid whitesmoke;
      color: var(--green100);
      text-align: justify;
      width: 100%;
      height: 300px;
      
      align-items: center;
      box-shadow: 10px 10px 8px var(--gray500);
  }
  .button-ghost2 {
      color: var(--gray100);
      height: 50px;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      margin: 4px 2px;
      cursor: pointer;
  }
  
  .button-ghost2:hover {
      height: 50px;
      border-bottom: solid 3px;
      border-color: var(--gold);
      color: var(--gold);
      padding: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      margin: 4px 2px;
      cursor: pointer;
  }

  video {
      margin-top: 100px;
      width: 100%;
      height: auto;
      display: block;
      margin-left: 0;
  }
  .infographic{
      width: 100%;
      max-height: auto;
      border-radius: var(--border-radius);
      border: 1px solid;
      border-color: var(--green300);
      margin: 0;
      overflow: hidden;
  }

  .imagen-container {
      width: 800px;
      height: 2000px;
      max-width: 100%;
      margin: 0 auto;
  }
  
  @media only screen and (max-width: 600px) {
      .imagen-container {
          width: 100%;
      }
  }
  
  .item__infography{
      position: absolute;
      top: 100px;
      left: 5%;
      padding: 0.8rem;
      border: 1px solid;
      border-color: var(--green300);
      background-color: var(--green700); opacity: 0.9;
      overflow: hidden;
  }
  
  .item__infography:hover{
      position: absolute;
      top: 100px;
      left: 5%;
      padding: 0.8rem;
      border: 3px solid;
      border-color: var(--red500);
      background-color: var(--green700); opacity: 0.95;
      overflow: hidden;
      color: var(--white);
  }
  .card__infography{
      align-items: center;
      color: var(--gold);
      font-size: 14px;
      width: 100%;
  }
  
}
.column{
  display: grid;
  gap: 0.5em;
}
@media screen and (min-width:720px){
  .column--left{
      grid-template-columns: min-content 1fr; 
  }
  
}
@media screen and (min-width:720px){
  .column--right{
      grid-template-columns: 600px; 
      grid-template-rows: repeat(1,min-content);
  }
}


